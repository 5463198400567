import { ChangeElement } from "shared-library/types";

const timestampToLocaleString = (timestamp: number) => {
    return timestampToDate(timestamp).toLocaleString('de-AT');
}

const timestampToDate = (timestamp: number) => {
    return new Date(timestamp * 1000);
}

const sortDates = (dates: Date[]) => {
    return dates.sort((a: Date, b: Date) => b.valueOf() - a.valueOf())
}

const convertDateToDatePickerString = (date: Date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading 0 if needed
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

const isWithinTimeWindow = (changeElement: ChangeElement, timeWindowStart: number, timeWindowEnd: number) => {
    return changeElement.originalTimestamp === undefined || (changeElement.originalTimestamp >= timeWindowStart && changeElement.originalTimestamp <= timeWindowEnd);
}

export { timestampToLocaleString, timestampToDate, sortDates, convertDateToDatePickerString, isWithinTimeWindow }