import { darken } from "polished";
import { MouseEvent, useContext } from "react";
import { AuthorModeContext } from "../contexts/AuthorModeContext";
import { ModificationProps } from "../props";
import { authorToColor } from "../utils/authors";
import { timestampToLocaleString } from "../utils/dates";
import { hideTooltip, renderTooltip } from "../utils/tooltips";

const DEFAULT_MODIFICATION_OPACITY = 1;
const DEFAULT_MODIFICATION_WIDTH = 4;

const Modification = (({ filepath, changeElement, x, y, width = DEFAULT_MODIFICATION_WIDTH, height, color, stroke = color, opacity = DEFAULT_MODIFICATION_OPACITY, showTooltip = true, onClickHandler }: ModificationProps) => {
    const { authorMode, authors } = useContext(AuthorModeContext);

    const { timestamp, status, commit: { oid, message, author: commitAuthor }, hidden } = changeElement;

    let hideModification = hidden

    const handleOnClick = () => {
        if (onClickHandler) {
            onClickHandler()
        }
    }

    const handleOnHover = (event: MouseEvent) => {
        if (!showTooltip || hidden) {
            return;
        }
        renderTooltip('tooltip', event.pageX, event.pageY,
            `<div>${timestampToLocaleString(timestamp)}</div>
            <div>${commitAuthor.email}</div>
            <div>${status}</div>
            <div>${message}</div>
            <div>${oid}</div>`)
    }

    const handleOnHoverLeave = () => {
        hideTooltip('tooltip')
    }

    const author = authors.find(a => a.email === commitAuthor.email);

    if (author?.checked === false) {
        hideModification = true;
    }

    return (<rect
        id={`change-${filepath}-${timestamp}-${status}-${oid}`}
        key={`change-${filepath}-${timestamp}-${status}-${oid}`}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={authorMode ? authorToColor(commitAuthor) : (color && darken(0.25, color))}
        fillOpacity={hideModification ? 0 : opacity}
        stroke={stroke}
        onClick={handleOnClick}
        onMouseOver={handleOnHover}
        onMouseLeave={handleOnHoverLeave}
        pointerEvents={hideModification ? 'none' : 'auto'}
    ></rect>)
});

export default Modification;